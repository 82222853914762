import React from "react"

import english from "../images/classes/english.png"
import music from "../images/classes/music.png"
import plastic from "../images/classes/plastic.png"
import outdoor from "../images/classes/outdoor.png"
import reading from "../images/classes/reading.png"
import education from "../images/classes/education.png"

class Classes extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <section id="section-classes">
        <div>
          <div id="english" className="classes-section">
            <div className="container">
              <div className="classes-item">
                <div className="row">
                  <div className="classes-item-image">
                    <img src={english} width="100%" />
                  </div>
                  <div className="classes-item-text">
                    <div className="content">
                      <div className="title">
                        <span>Język angielski</span>
                      </div>
                      <span>
                        Zajęcia mają na celu bezstresowe osłuchanie się najmłodszych z językiem angielskim poprzez
                        zabawę, muzykę i taniec, a także przy wykorzystaniu pomocy dydaktycznych. Śpiewamy więc piosenki
                        po angielsku, mówimy rymowanki, tańczymy, a wszystko to w atmosferze beztroskiej zabawy.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="music-move" className="classes-section">
            <div className="container">
              <div className="classes-item">
                <div className="row">
                  <div className="classes-item-text">
                    <div className="content">
                      <div className="title">
                        <span>Zajęcia muzyczno-ruchowe</span>
                      </div>
                      <span>
                        Razem z dziećmi słuchamy muzyki, tańczymy i śpiewamy, gramy na instrumentach, prowadzimy
                        gimnastykę, bawimy się chustą Klanzą, organizujemy koncerty muzyków – rozwija to wrażliwość
                        muzyczną i estetyczną, kształci zdolności słuchowe, spostrzegawczość, kreatywność, koordynację
                        ruchową, samodzielność, ćwiczy pamięć i współpracę z innymi, a przy tym wszystkim wyzwala wiele
                        radości i śmiechu.
                      </span>
                    </div>
                  </div>
                  <div className="classes-item-image">
                    <img src={music} width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="plastic" className="classes-section">
            <div className="container">
              <div className="classes-item">
                <div className="row">
                  <div className="classes-item-image">
                    <img src={plastic} width="100%" />
                  </div>
                  <div className="classes-item-text">
                    <div className="content">
                      <div className="title">
                        <span>Zajęcia plastyczne</span>
                      </div>
                      <span>
                        Podczas zajęć plastycznych wykorzystujemy różnorodne techniki jak rysowanie kredkami, farbami,
                        malowanie palcami, stemplowanie, prace wykonane z makaronów, włóczek, szmatek, masy solnej i
                        innych. Zajęcia te rozwijają u dzieci kreatywność, wyobraźnię, zdolności manualne, ekspresję
                        plastyczną, budują wiarę w siebie i poczucie własnej wartości, uczą cierpliwości, koncentracji i
                        przygotowują małe rączki do nauki pisania w przyszłości.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="outdoor" className="classes-section">
            <div className="container">
              <div className="classes-item">
                <div className="row">
                  <div className="classes-item-text">
                    <div className="content">
                      <div className="title">
                        <span>Zajęcia na świeżym powietrzu</span>
                      </div>
                      <span>
                        Staramy się aby nasze maluchy dużo czasu spędzały na świeżym powietrzu. Wychodzimy na spacery,
                        organizujemy zajęcia oraz zabawy, a przy tym obserwujemy i poznajemy przyrodę i otaczający na
                        świat.
                      </span>
                    </div>
                  </div>
                  <div className="classes-item-image">
                    <img src={outdoor} width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="education" className="classes-section">
            <div className="container">
              <div className="classes-item">
                <div className="row">
                  <div className="classes-item-image">
                    <img src={education} width="100%" />
                  </div>
                  <div className="classes-item-text">
                    <div className="content">
                      <div className="title">
                        <span>Zajęcia ogólnorozwojowe, edukacyjne</span>
                      </div>
                      <span>
                        Uczymy dzieci samodzielności poprzez m.in.: nocnikowanie, naukę trzymania kubeczka, posługiwanie
                        się sztućcami, ubieranie się. Dzieci w zabawach ćwiczą wszystkie zmysły, umiejętności językowe,
                        mowę, koordynację wzrokowo-ruchową oraz uczą się współpracy z rówieśnikami, poznają nowe rzeczy.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="reading" className="classes-section">
            <div className="container">
              <div className="classes-item">
                <div className="row">
                  <div className="classes-item-text">
                    <div className="content">
                      <div className="title">
                        <span>Czytanie bajek</span>
                      </div>
                      <span>
                        Czytanie bajek, wierszyków, rymowanek. Czytanie dzieciom bajek ma na celu wspieranie ich rozwoju
                        emocjonalno-poznawczego i kształtowanie wyobraźni.
                      </span>
                    </div>
                  </div>
                  <div className="classes-item-image">
                    <img src={reading} width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Classes
