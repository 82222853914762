import React from "react"
import baguetteBox from "baguettebox.js"

import "baguettebox.js/dist/baguetteBox.min.css"
import "../styles/gallery.scss"

import jpg1 from "../gallery/1.jpg"
import jpg1min from "../gallery/1_min.jpg"
import jpg2 from "../gallery/2.jpg"
import jpg2min from "../gallery/2_min.jpg"
import jpg3 from "../gallery/3.jpg"
import jpg3min from "../gallery/3_min.jpg"
import jpg4 from "../gallery/4.jpg"
import jpg4min from "../gallery/4_min.jpg"
import jpg5 from "../gallery/5.jpg"
import jpg5min from "../gallery/5_min.jpg"
import jpg6 from "../gallery/6.jpg"
import jpg6min from "../gallery/6_min.jpg"
import jpg7 from "../gallery/7.jpg"
import jpg7min from "../gallery/7_min.jpg"
import jpg8 from "../gallery/8.jpg"
import jpg8min from "../gallery/8_min.jpg"
import jpg9 from "../gallery/9.jpg"
import jpg9min from "../gallery/9_min.jpg"
import jpg10 from "../gallery/10.jpg"
import jpg10min from "../gallery/10_min.jpg"
import jpg11 from "../gallery/11.jpg"
import jpg11min from "../gallery/11_min.jpg"
import jpg12 from "../gallery/12.jpg"
import jpg12min from "../gallery/12_min.jpg"
import jpg13 from "../gallery/13.jpg"
import jpg13min from "../gallery/13_min.jpg"
import jpg14 from "../gallery/14.jpg"
import jpg14min from "../gallery/14_min.jpg"
import jpg15 from "../gallery/15.jpg"
import jpg15min from "../gallery/15_min.jpg"
import jpg16 from "../gallery/16.jpg"
import jpg16min from "../gallery/16_min.jpg"
import jpg17 from "../gallery/17.jpg"
import jpg17min from "../gallery/17_min.jpg"
import jpg18 from "../gallery/18.jpg"
import jpg18min from "../gallery/18_min.jpg"
import jpg19 from "../gallery/19.jpg"
import jpg19min from "../gallery/19_min.jpg"
import jpg20 from "../gallery/20.jpg"
import jpg20min from "../gallery/20_min.jpg"
import jpg21 from "../gallery/21.jpg"
import jpg21min from "../gallery/21_min.jpg"
import jpg22 from "../gallery/22.jpg"
import jpg22min from "../gallery/22_min.jpg"
import jpg23 from "../gallery/23.jpg"
import jpg23min from "../gallery/23_min.jpg"
import jpg24 from "../gallery/24.jpg"
import jpg24min from "../gallery/24_min.jpg"
import jpg25 from "../gallery/25.jpg"
import jpg25min from "../gallery/25_min.jpg"
import jpg26 from "../gallery/26.jpg"
import jpg26min from "../gallery/26_min.jpg"
import jpg27 from "../gallery/27.jpg"
import jpg27min from "../gallery/27_min.jpg"
import jpg28 from "../gallery/28.jpg"
import jpg28min from "../gallery/28_min.jpg"
import jpg29 from "../gallery/29.jpg"
import jpg29min from "../gallery/29_min.jpg"
import jpg32 from "../gallery/32.jpg"
import jpg32min from "../gallery/32_min.jpg"
import jpg33 from "../gallery/33.jpg"
import jpg33min from "../gallery/33_min.jpg"
import jpg34 from "../gallery/34.jpg"
import jpg34min from "../gallery/34_min.jpg"
import jpg35 from "../gallery/35.jpg"
import jpg35min from "../gallery/35_min.jpg"
import jpg36 from "../gallery/36.jpg"
import jpg36min from "../gallery/36_min.jpg"
import jpg37 from "../gallery/37.jpg"
import jpg37min from "../gallery/37_min.jpg"
import jpg38 from "../gallery/38.jpg"
import jpg38min from "../gallery/38_min.jpg"
import jpg39 from "../gallery/39.jpg"
import jpg39min from "../gallery/39_min.jpg"
import jpg40 from "../gallery/40.jpg"
import jpg40min from "../gallery/40_min.jpg"
import jpg41 from "../gallery/41.jpg"
import jpg41min from "../gallery/41_min.jpg"
import jpg42 from "../gallery/42.jpg"
import jpg42min from "../gallery/42_min.jpg"
import jpg43 from "../gallery/43.jpg"
import jpg43min from "../gallery/43_min.jpg"
import jpg44 from "../gallery/44.jpg"
import jpg44min from "../gallery/44_min.jpg"
import jpg45 from "../gallery/45.jpg"
import jpg45min from "../gallery/45_min.jpg"
import jpg46 from "../gallery/46.jpg"
import jpg46min from "../gallery/46_min.jpg"
import jpg47 from "../gallery/47.jpg"
import jpg47min from "../gallery/47_min.jpg"
import jpg48 from "../gallery/48.jpg"
import jpg48min from "../gallery/48_min.jpg"
import jpg49 from "../gallery/49.jpg"
import jpg49min from "../gallery/49_min.jpg"
import jpg50 from "../gallery/50.jpg"
import jpg50min from "../gallery/50_min.jpg"
import jpg51 from "../gallery/51.jpg"
import jpg51min from "../gallery/51_min.jpg"
import jpg52 from "../gallery/52.jpg"
import jpg52min from "../gallery/52_min.jpg"
import jpg53 from "../gallery/53.jpg"
import jpg53min from "../gallery/53_min.jpg"
import jpg54 from "../gallery/54.jpg"
import jpg54min from "../gallery/54_min.jpg"
import jpg55 from "../gallery/55.jpg"
import jpg55min from "../gallery/55_min.jpg"

class Gallery extends React.PureComponent {
  public componentDidMount() {
    baguetteBox.run(".tz-gallery")
  }

  public render(): React.ReactNode {
    return (
      <section id="section-gallery">
        <div>
          <div className="container">
            <div className="row">
              <div className="offset-1 col-10">
                <div className="tz-gallery">
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg1}>
                        <img src={jpg1min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg2}>
                        <img src={jpg2min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg3}>
                        <img src={jpg3min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg4}>
                        <img src={jpg4min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg5}>
                        <img src={jpg5min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg6}>
                        <img src={jpg6min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg7}>
                        <img src={jpg7min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg8}>
                        <img src={jpg8min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg9}>
                        <img src={jpg9min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg10}>
                        <img src={jpg10min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg11}>
                        <img src={jpg11min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg12}>
                        <img src={jpg12min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg13}>
                        <img src={jpg13min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg14}>
                        <img src={jpg14min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg15}>
                        <img src={jpg15min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg16}>
                        <img src={jpg16min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg17}>
                        <img src={jpg17min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg18}>
                        <img src={jpg18min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg19}>
                        <img src={jpg19min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg20}>
                        <img src={jpg20min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg21}>
                        <img src={jpg21min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg22}>
                        <img src={jpg22min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg23}>
                        <img src={jpg23min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg24}>
                        <img src={jpg24min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg25}>
                        <img src={jpg25min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg26}>
                        <img src={jpg26min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg27}>
                        <img src={jpg27min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg28}>
                        <img src={jpg28min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg29}>
                        <img src={jpg29min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg32}>
                        <img src={jpg32min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg33}>
                        <img src={jpg33min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg34}>
                        <img src={jpg34min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg35}>
                        <img src={jpg35min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg36}>
                        <img src={jpg36min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg37}>
                        <img src={jpg37min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg38}>
                        <img src={jpg38min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg39}>
                        <img src={jpg39min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg40}>
                        <img src={jpg40min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg41}>
                        <img src={jpg41min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg42}>
                        <img src={jpg42min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg43}>
                        <img src={jpg43min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg44}>
                        <img src={jpg44min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg45}>
                        <img src={jpg45min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg46}>
                        <img src={jpg46min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg47}>
                        <img src={jpg47min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg48}>
                        <img src={jpg48min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg49}>
                        <img src={jpg49min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg50}>
                        <img src={jpg50min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg51}>
                        <img src={jpg51min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg52}>
                        <img src={jpg52min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg53}>
                        <img src={jpg53min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg54}>
                        <img src={jpg54min} width="100%" />
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <a className="lightbox" href={jpg55}>
                        <img src={jpg55min} width="100%" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Gallery
