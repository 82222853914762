import React from "react"

class DaySchedule extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <section id="section-dayschedule">
        <div>
          <div className="container">
            <div className="row">
              <div className="offset-1 col-10">
                <div className="title">
                  <span>Plan dnia</span>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>07:00 - 08:30</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Przyjmowanie dzieci do żłobka.</span>
                  </div>
                  <ul>
                    <li>indywidualny kontakt-rozmowy opiekunek z rodzicami</li>
                    <li>zabawy dwolne służące realizacji pomysłów dzieci</li>
                    <li>gimnastyka "całego smyka"</li>
                  </ul>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>08:30 - 09:00</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Śniadanie.</span>
                  </div>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>09:00 - 09:30</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Spacer, plac zabaw.</span>
                  </div>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>09:30 - 10:30</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Zajęcia dodatkowe.</span>
                  </div>
                  <ul>
                    <li>język angielski</li>
                    <li>zajęcia muzyczne</li>
                    <li>zajęcia edukacujne opracowane na tygodniach tematycznych</li>
                    <li>zabawy ogólnorozwojowe</li>
                  </ul>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>10:30 - 11:00</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Obiad (|| danie).</span>
                  </div>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>11:00 - 13:00</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Sen dzieci, odpoczynek.</span>
                  </div>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>13:15 - 13:45</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Obiad (zupka).</span>
                  </div>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>13:45 - 14:00</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Toaleta (mycie rąk, mycie zębów).</span>
                  </div>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>14:00 - 15:00</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Zajęcia dodatkowe.</span>
                  </div>
                  <ul>
                    <li>zabawy manipulacyjno-konstrukcyjne</li>
                    <li>zabawy plastyczne</li>
                    <li>zabawy sensoryczne</li>
                  </ul>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>15:00 - 15:30</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>Podwieczorek.</span>
                  </div>
                </div>
                <div className="dayschedule-item">
                  <div className="dayschedule-item-head">
                    <span>15:30 - 17:00</span>
                  </div>
                  <div className="dayschedule-item-body">
                    <span>
                      Czytanie książek, utrwalanie piosenek, zabawy ruchowe, zabawy dowolne przed powrotem do domu.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DaySchedule
