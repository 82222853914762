import React from "react"

class Home extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <section id="section-home">
        <div>
          <div className="container">
            <div className="row">
              <div className="offset-1 col-10">
                <span className="">
                  Pierwsze trzy lata życia dziecka to <strong> najważniejszy okres w jego życiu</strong>. Nie tylko
                  zaczyna wtedy chodzić i mówić, ale także nawiązywać relacje z innymi i wyrażać swoje emocje.
                  Najważniejsze jest wtedy zapewnienie mu poczucia bezpieczeństwa i zaspokajanie jego potrzeb w różnych
                  sferach, stworzenie przyjaznego środowiska, które umożliwi harmonijny rozwój. To wszystko, co otrzyma
                  teraz, będzie jego potencjałem na przyszłość – im więcej pozytywnych doświadczeń, tym lepszy start w
                  dorosłe życie i pewniejsza droga do osiągania sukcesów.
                </span>
                <div className="list">
                  <div>
                    <span>
                      <span className="list-item-head"></span> to idealne miejsce dla twojego dziecka.
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="list-item-head"></span> to ciepła i domowa atmosfera – najlepsze warunki rozwoju
                      dziecka.
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="list-item-head"></span> to doskonała opieka.
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="list-item-head"></span> to wykształcone, profesjonalne opiekunki, które z sercem
                      i zrozumieniem podchodzą do każdego dziecka indywidualnie.
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="list-item-head"></span> to bezpieczne i niebanalne wnętrza, przyjazne i
                      oryginalne zabawki, selektywnie dobrane pomoce dydaktyczne.
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="list-item-head"></span> to przede wszystkim zabawa, która rozwija.
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="list-item-head"></span> to miejsce gdzie nauka jest zabawą, a śmiech dziecka i
                      poczucie spokoju rodziców jest dla nas najcenniejszym podziękowaniem.
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="list-item-head"></span> to szeroki wachlarz zajęć dodatkowych, odpowiednio
                      dobrany do rozwoju i możdivwości dziecka.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Home
