import React from "react"

interface IContactProps {}

interface IContactState {
  name: string
  email: string
  subject: string
  body: string
}

class Contact extends React.PureComponent<IContactProps, IContactState> {
  public constructor(props: IContactProps) {
    super(props)
    this.state = {
      name: "",
      email: "",
      subject: "",
      body: "",
    }
  }

  public componentDidMount(): void {
    setTimeout(this.initMap, 300)
  }

  private initMap(): void {
    //@ts-ignore
    if (typeof google !== "undefined") {
      const uluru = {
        lat: 52.46139474304567,
        lng: 16.873932498444475,
      }

      //@ts-ignore
      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: uluru,
        //@ts-ignore
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      })
      //@ts-ignore
      const marker = new google.maps.Marker({
        position: uluru,
        map: map,
        title: "ŻŁOBEK SMYKI",
      })
    } else {
      setTimeout(this.initMap, 50)
    }
  }

  public render(): React.ReactNode {
    return (
      <section id="section-contact">
        <div>
          <div className="container">
            <div className="row">
              <div className="offset-1 col-10">
                <div className="title">
                  <span>Napisz do nas</span>
                </div>
                <form method="post">
                  <div className="form-group row">
                    <label htmlFor="contact-name" className="contact-form-label">
                      Imię i Nazwisko
                    </label>
                    <div className="contact-form-input">
                      <input
                        type="text"
                        className="form-control form-control-plaintext"
                        id="contact-name"
                        value={this.state.name}
                        onChange={(e) => this.setState({ name: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="contact-email" className="contact-form-label">
                      Adres e-mail
                    </label>
                    <div className="contact-form-input">
                      <input
                        type="text"
                        className="form-control form-control-plaintext"
                        id="contact-email"
                        value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="contact-subject" className="contact-form-label">
                      Temat
                    </label>
                    <div className="contact-form-input">
                      <input
                        type="text"
                        className="form-control form-control-plaintext"
                        id="contact-subject"
                        value={this.state.subject}
                        onChange={(e) => this.setState({ subject: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="contact-body" className="contact-form-label">
                      Wiadomość
                    </label>
                    <div className="contact-form-input">
                      <textarea
                        rows={6}
                        className="form-control form-control-plaintext"
                        id="contact-body"
                        value={this.state.body}
                        onChange={(e) => this.setState({ body: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="contact-form-button">
                      <div className="btn btn-primary w-100" onClick={() => this.sendEmail()}>
                        Wyślij
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="map" className="google-map"></div>
      </section>
    )
  }

  private sendEmail(): void {
    const to: string = "kontakt@zlobeksmyki.pl"
    const subject: string = this.state.subject
    const body: string = this.state.name + "(" + this.state.email + ") \n\n" + this.state.body

    const mail = document.createElement("a")
    mail.href = "mailto:" + to + "?subject=" + subject + "&body=" + body
    mail.click()
  }
}

export default Contact
