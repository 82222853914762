import React from "react"

class FirstDay extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <section id="section-firstdays">
        <div>
          <div className="container">
            <div className="row">
              <div className="offset-1 col-10">
                <div className="title">
                  <span>Pierwsze dni u nas</span>
                </div>
                <div className="list-title">Zajęcia adaptacyjne</div>
                <div className="list">
                  <div>
                    <span className="icon-heart"></span>
                    <span>
                      To bardzo ważny element przystosowawczy dla dziecka i dla opiekunek. W zależności od wieku
                      dziecka, stopnia przywiązania do rodziców i reakcji na nowe otocznie, ustalamy kilka spotkań, na
                      które przychodzą dzieci wraz z rodzicami.
                    </span>
                  </div>
                  <div>
                    <span className="icon-heart"></span>
                    <span>
                      Każde spotkanie odbywa się w grupie, do której dziecko ma uczęszczać, w godzinach wcześniej
                      ustalonych z opiekunką.
                    </span>
                  </div>
                  <div>
                    <span className="icon-heart"></span>
                    <span>
                      Pierwszy dzień adaptacji to obserwacja. Opiekunka nie ingeruje w obecność nowego dziecka, pozwala
                      mu zaznajomić się z salą, zabawkami i rówieśnikami.
                    </span>
                  </div>
                  <div>
                    <span className="icon-heart"></span>
                    <span>
                      W następnych dniach, zachęcamy dziecko do wspólnej zabawy, staramy się znaleźć coś, co w sposób
                      szczególny spodoba sie maluszkowi. Stopniowo odwracamy uwagę od rodzica, a po pewnym czasie
                      pozwalamy rodzicom na chwilowe oddalenie się z sali.
                    </span>
                  </div>
                  <div>
                    <span className="icon-heart"></span>
                    <span>W ostatnim dniu rozłąka jest dłuższa np. na dwie godziny.</span>
                  </div>
                  <div>
                    <span className="icon-heart"></span>
                    <span>
                      Drodzy rodzice, proces adaptacji nie może trwać zbyt długo. Nawet jeśli dziecko przeżywa rozstanie
                      z opiekunem to musimy pamiętać, że długie rozstania jeszcze bardziej potęgują rozpacz.
                    </span>
                  </div>
                  <div>
                    <span className="icon-heart"></span>
                    <span>
                      Chcemy aby dziecko nam zaufało, aby dało ponieść się zabawie i w spokoju oczekiwało na Wasze
                      przyjście.
                    </span>
                  </div>
                </div>
                <div className="text-justify padding-top-1000">
                  Dla każdego Malucha przypisane jest łóżeczko, z którego nie korzysta żadne inne dziecko. W naszym
                  Żłobku każde dziecko otrzymuje swoją pościel, kołderkę i poduszkę oraz nocnik.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FirstDay
