import React from "react"
import Carousel from "../sections/Carousel"
import Contact from "../sections/Contact"
import DaySchedule from "../sections/DaySchedule"
import FirstDay from "../sections/FirstDays"
import Home from "../sections/Home"

class HomePage extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <>
        <Carousel />
        <Home />
        <DaySchedule />
        <FirstDay />
        {/*<Charges />*/}
        <Contact />
      </>
    )
  }
}

export default HomePage
