import React from "react"

import Gallery from "../sections/Gallery"

class GalleryPage extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <>
        <Gallery />
      </>
    )
  }
}

export default GalleryPage
