import React from "react"
import Classes from "../sections/Classes"

class ClassesPage extends React.PureComponent {
  public render(): React.ReactNode {
    return (
      <>
        <Classes />
      </>
    )
  }
}

export default ClassesPage
