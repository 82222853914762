import React from "react"
import { withRouter, RouteComponentProps } from "react-router"

interface ICarouselProp extends RouteComponentProps {}

class Carousel extends React.PureComponent<ICarouselProp> {
  private readonly navbarHeight: number = 90

  public render(): React.ReactNode {
    return (
      <section id="section-carousel">
        <div
          id="carousel"
          style={{ height: document.documentElement.clientHeight - this.navbarHeight + "px" }}
          className="carousel slide"
          data-ride="carousel"
          data-interval="2000"
        >
          <div className="carousel-scroll-container">
            <div onClick={() => this.props.history.push("home")}>
              <i className="icon-down"></i>
            </div>
          </div>
          <div className="carousel-inner" style={{ backgroundPosition: "top " + this.navbarHeight + "px" }}>
            <div className="carousel-item active">
              <div className="carousel-item-background">
                <span>Zapraszamy!</span>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-item-background">
                <span>
                  Wszystkie dzieci od 20
                  <br /> tygodnia życia do 3 lat
                </span>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-item-background">
                <span>Zapisy trwają cały rok!</span>
              </div>
            </div>
          </div>,
        </div>
      </section>
    )
  }
}

export default withRouter(Carousel)
